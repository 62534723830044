import React, { useState } from 'react';

const Carousel = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstItem = currentIndex === 0;
    const newIndex = isFirstItem ? children.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastItem = currentIndex === children.length - 1;
    const newIndex = isLastItem ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div>
      <div className="carousel-container">
        <button onClick={goToPrevious}>{'<'}</button>
        <div className="carousel-content">
          {React.Children.toArray(children)[currentIndex]}
        </div>
        <button onClick={goToNext}>{'>'}</button>
      </div>
    </div>
  );
};

export default Carousel;