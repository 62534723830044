import React, { useState, useEffect } from "react";
import { FaHome } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import styles from "./style.module.scss"; // Assurez-vous d'ajuster le chemin selon l'emplacement de votre fichier CSS

const MenuBar = ({ getCryptoLogoUrl, displayCryptoDetails }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [recoList, setRecoList] = useState([]);

  useEffect(() => {
    // Fonction pour mettre à jour la liste de recommandations depuis le stockage local
    const updateRecoListFromLocalStorage = () => {
      const storedRecoList = localStorage.getItem("recommendations");
      if (storedRecoList) {
        setRecoList(JSON.parse(storedRecoList));
      }
    };

    // Mettre à jour la liste de recommandations lors du montage initial
    updateRecoListFromLocalStorage();

    // Écouter les changements du stockage local
    const handleStorageChange = (event) => {
      if (event.key === "recommendations") {
        updateRecoListFromLocalStorage();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []); // S'effectue uniquement lors du montage initial

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const refreshRecommendations = () => {
    // Forcer la mise à jour de la liste de recommandations depuis le stockage local
    const storedRecoList = localStorage.getItem("recommendations");
    if (storedRecoList) {
      setRecoList(JSON.parse(storedRecoList));
    }
  };

  return (
    <div className={styles.menu_bar}>
      <button className={styles.menu_button} onClick={toggleMenu}>
        <FaHome className={styles.logo_home} />
      </button>
      <div className={`${styles.menu} ${isMenuOpen ? styles.open : ""}`}>
        <button className={styles.close_button} onClick={closeMenu}>
          <MdOutlineClose className={styles.logo_close} />
        </button>
        <button
          className={styles.refresh_button}
          onClick={refreshRecommendations}
        >
          <span className={styles.button_text}>Refresh</span>
          <span className={styles.button_icon}>
            <svg
              className={styles.svg}
              height="48"
              viewBox="0 0 48 48"
              width="48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M35.3 12.7c-2.89-2.9-6.88-4.7-11.3-4.7-8.84 0-15.98 7.16-15.98 16s7.14 16 15.98 16c7.45 0 13.69-5.1 15.46-12h-4.16c-1.65 4.66-6.07 8-11.3 8-6.63 0-12-5.37-12-12s5.37-12 12-12c3.31 0 6.28 1.38 8.45 3.55l-6.45 6.45h14v-14l-4.7 4.7z"></path>
              <path d="M0 0h48v48h-48z" fill="none"></path>
            </svg>
          </span>
        </button>
        <ul>
          {/* Afficher la liste de recommandations */}
          {recoList.map((recommendation, index) => (
            <li
              className={styles.list}
              key={index}
              onClick={() => displayCryptoDetails(recommendation)}
            >
              <img
                src={getCryptoLogoUrl(recommendation)}
                alt={recommendation}
                className={styles.img}
              />
              {recommendation}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MenuBar;
