import React from 'react';

const width = 200; // Largeur du SVG
const height = 100; // Hauteur du SVG (pour demi-cercle)
const strokeWidth = 14; // Épaisseur de la ligne de la jauge
const radius = (width - strokeWidth) / 2; // Rayon de la jauge

const MultiLevelGauge = ({ score }) => {
  const circumference = Math.PI * radius; // Circonférence du demi-cercle
  const scorePercent = score / 100; // Pourcentage du score
  const needleRotation = (180 * scorePercent) - 90; // Rotation de l'aiguille

  // Calcul de l'offset pour chaque segment
  const segmentLength = circumference / 3; // La longueur de chaque segment est un tiers de la demi-circonférence
  const redOffset = segmentLength * 2; // Rouge commence après le vert et orange
  const orangeOffset = segmentLength; // Orange commence après le vert
  const greenOffset = 0; // Vert commence en premier

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        {/* Segment rouge */}
        <circle
          cx={width / 2}
          cy={height}
          r={radius}
          fill="none"
          stroke="#008000"
          strokeWidth={strokeWidth}
          strokeDasharray={segmentLength + ' ' + (circumference - segmentLength)}
          strokeDashoffset={-redOffset}
        />
        {/* Segment orange */}
        <circle
          cx={width / 2}
          cy={height}
          r={radius}
          fill="none"
          stroke="#ffaf4d"
          strokeWidth={strokeWidth}
          strokeDasharray={segmentLength + ' ' + (circumference - segmentLength)}
          strokeDashoffset={-orangeOffset}
        />
        {/* Segment vert */}
        <circle
          cx={width / 2}
          cy={height}
          r={radius}
          fill="none"
          stroke="#ff4d4d"
          strokeWidth={strokeWidth}
          strokeDasharray={segmentLength + ' ' + (circumference - segmentLength)}
          strokeDashoffset={-greenOffset}
        />
        {/* Aiguille */}
        <line
          x1={width / 2}
          y1={height}
          x2={width / 2}
          y2={height - radius + strokeWidth / 2}
          stroke="black"
          strokeWidth="2"
          transform={`rotate(${needleRotation} ${width / 2} ${height})`}
        />
        {/* Centre de l'aiguille */}
        <circle cx={width / 2} cy={height} r={strokeWidth / 2} fill="black" />
      </svg>
      <div style={{ marginTop: "10px" }}>Score: {score}%</div>
    </div>
  );
};

export default MultiLevelGauge;
