import React, { useEffect, useState } from 'react';
import { FiLogOut } from "react-icons/fi";
import { Auth, Hub } from 'aws-amplify';
import { Button } from '@aws-amplify/ui-react';

const NavBar = () => {
  const [user, setUser] = useState(null);

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  useEffect(() => {

  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px", position: "fixed", top: 0, width: "90%" }}>
      <div>
        <img src="/logo.png" style={{ marginLeft: "10px", width: "230px", height: "170px", marginTop: "40px" }} />
      </div>
      <div>
        <img src="/bim.jpeg" style={{ marginRight: "50px", width: "50px", height: "50px", marginTop: "40px" }} />
      </div>
    </div>
  );
};

export default NavBar;
