import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Translate, Translator } from "react-auto-translate";

const WarningPopup = ({ selectedLanguage, cacheProvider }) => {
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    // Récupérer le timestamp de la dernière fois que le popup a été fermé
    const lastClosedTime = localStorage.getItem("lastClosedTime");
    const currentTime = new Date().getTime();

    // Vérifier si 6 heures se sont écoulées depuis la dernière fermeture du popup
    if (
      lastClosedTime &&
      currentTime - parseInt(lastClosedTime) < 6 * 60 * 60 * 1000
    ) {
      setShowPopup(false); // Ne pas afficher le popup s'il a été fermé récemment
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
    localStorage.setItem("lastClosedTime", new Date().getTime().toString());
  };

  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        to={selectedLanguage}
        from="fr"
        googleApiKey="AIzaSyDdBSx0ogSpfG_7UDLS72WZTpkJmAyAXEw"
      >
        {showPopup && (
          <div className={styles.card}>
            <p className={styles.cookieHeading}>
              <Translate>Important :</Translate>
            </p>
            <p className={styles.cookieDescription}>
              <Translate>
                En partenariat avec 3Decrypt, nous vous proposons des outils
                pour éclairer vos décisions et vous permettre d’exploiter
                pleinement les opportunités du marché des cryptomonnaies. Notre
                objectif est de rendre votre expérience simple et transparente.
              </Translate>
            </p>
            <p className={styles.cookieDescription}>
              <Translate>
                Accédez à des recommandations personnalisées, des analyses et
                des audits accessibles pour tous, afin de vous guider
                efficacement à travers ce marché à la fois complexe et
                fascinant. Bienvenue dans le futur de l'investissement crypto
                avec BIM Exchange x 3Decrypt !
              </Translate>
            </p>
            <p className={styles.cookieDescription}>
              <Translate>
                ⚠️ Investir en cryptomonnaies comporte des risques. Ne dépensez
                que ce que vous pouvez vous permettre de perdre. Les
                informations de 3Decrypt sont informatives, mais ne sont pas des
                conseils financiers ⚠️
              </Translate>
            </p>

            <div className={styles.buttonContainer}>
              <button onClick={handleClose} className={styles.acceptButton}>
                <Translate>Accepter</Translate>
              </button>
              <button className={styles.declineButton}>
                <Translate>Décliner</Translate>
              </button>
            </div>
          </div>
        )}
      </Translator>
    </>
  );
};

export default WarningPopup;
