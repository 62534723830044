import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import NavBar from "./components/NavBar";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserForm from "./components/UserForm"
import QuestionsForm from './components/QuestionsForm'
import ResultsShow from "./components/ResultsShow";
import CryptoPopup from './components/CryptoPopup'

import { Translator, Translate } from 'react-auto-translate';
import WarningPopup from "./components/WarningPopup";
import MenuBar from "./components/MenuBar";

Amplify.configure(awsconfig);

const App = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [recommendations, setRecommendations] = useState("");
  const [cryptoIcons, setCryptoIcons] = useState({});
  const [userInfo, setUserInfo] = useState(null);
  const [attempts, setAttempts] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [language, setLanguage] = useState('EN'); // Default language set to English

  // Obtenez l'URL complète
  const fullUrl = window.location.href;

  // Extrait le domaine
  const domain = new URL(fullUrl).hostname;

  // Par défaut, le pays est défini sur 'fr' pour la France
  let country = 'fr';
  let platform = '';

  // Vérifiez si le domaine contient un indicateur de pays spécifique
  // Nous supposons que le format est sous-domaine.domaine.tld
  const domainParts = domain.split('.');
  platform = domainParts[0]

  // Si le nom de domaine a au moins 3 parties et que le sous-domaine contient '-'
  if (domainParts.length >= 3 && domainParts[0].includes('-')) {
    // Extrait le code pays du sous-domaine (tout ce qui se trouve après le dernier '-')
    const subDomainParts = domainParts[0].split('-');
    const countryIndicator = subDomainParts[subDomainParts.length - 1];

    // Si le pays indicateur est trouvé et n'est pas égal à 'finance' (qui fait partie de votre domaine principal)
    if (countryIndicator && countryIndicator !== 'finance') {
      country = countryIndicator;
    }
    // Si le sous-domaine est exactement 'exchange-bim-finance', cela signifie que nous sommes sur le domaine principal (France)
    else if (domainParts[0] === 'exchange-bim-finance') {
      country = 'fr'; // Ceci est déjà défini par défaut, mais c'est pour clarifier la logique
    }
  }


  const cacheProvider = {
    isLocalStorageAvailable: () => {
      try {
        const test = "test-storage";
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
      } catch (e) {
        return false;
      }
    },

    get: (language, key) => {
      if (!cacheProvider.isLocalStorageAvailable()) return null;

      try {
        const translations = JSON.parse(localStorage.getItem('translations')) || {};
        return (translations[key] || {})[language];
      } catch (e) {
        console.error('Error reading from localStorage', e);
        return null;
      }
    },

    set: (language, key, value) => {
      if (!cacheProvider.isLocalStorageAvailable()) return;

      try {
        const existingTranslations = JSON.parse(localStorage.getItem('translations')) || {};
        const updatedTranslations = {
          ...existingTranslations,
          [key]: {
            ...existingTranslations[key],
            [language]: value
          }
        };
        localStorage.setItem('translations', JSON.stringify(updatedTranslations));
      } catch (e) {
        console.error('Error writing to localStorage', e);
      }
    },
  };

  useEffect(() => {
    // Obtenir la date actuelle
    const now = new Date();

    // Essayer de récupérer la date de la dernière visite
    const lastVisitTime = localStorage.getItem('lastVisitTime');
    if (lastVisitTime) {
      const lastVisitDate = new Date(parseInt(lastVisitTime, 10));
      const twoDaysInMs = 2 * 24 * 60 * 60 * 1000; // 2 jours en millisecondes

      // Vérifier si plus de 2 jours se sont écoulés depuis la dernière visite
      if (now - lastVisitDate > twoDaysInMs) {
        // Supprimer 'userRegistered' si plus de 2 jours se sont écoulés
        localStorage.removeItem('userRegistered');
        //console.log("'userRegistered' supprimé car plus de 2 jours se sont écoulés depuis la dernière visite.");
      }
    }

    // Mettre à jour la date de la dernière visite dans localStorage
    localStorage.setItem('lastVisitTime', now.getTime().toString());
    //console.log('Date de la dernière visite mise à jour.');

  }, []);




  const username = localStorage.getItem('userRegistered');


  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [selectedCrypto, setSelectedCrypto] = useState(null);

  const [attemptsExhausted, setAttemptsExhausted] = useState(false);

  const [allAttempts, setallAttempts] = useState(false);

  const [lastAttempt, setLastAttempt] = useState(false);

  const [cryptoReviews, setCryptoReviews] = useState([]);

  const [progress, setProgress] = useState(0);

  const [showEmailPopup, setShowEmailPopup] = useState(false);

  const [score, setScore] = useState(98); // Initialisé à 98

  const totalQuestions = questions.length;
  const progressPercentage = (progress / totalQuestions) * 100;



  useEffect(() => {
    fetchCryptoReviews();
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (localStorage.getItem('userRegistered') !== null) {
        const res = await axios.get(
          `https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/user_infos/${localStorage.getItem('userRegistered')}`
        );

        setUserInfo(res.data[0]);
        setAttempts(res.data[0].quizattempts);

        if (res.data[0].quizattempts === 0) {
          setallAttempts(true);
        }

        if (res.data[0].quizattempts >= 3) {
          setAttemptsExhausted(true);
        }
        if (res.data[0].quizattempts === 2) {
          setLastAttempt(true);
        }
      }
    };
    fetchUserInfo()
  }, []);


  useEffect(() => {
    if (showResults) {
      setIsLoading(true);
      axios
        .get(
          `https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/user_attempts/${username}`
        )
        .then((res) => {
          setAttempts(res.data.quizattempts);
        })
        .catch((err) => console.log(err));

      axios
        .get(
          `https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/user_recommendations/${username}`
        )
        .then((res) => {
          setRecommendations(res.data.recommendation);
          axios
            .get(
              `https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/delete_user_answers/${userInfo.id}`
            )
            .then((res) => { setIsLoading(false) })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  }, [showResults]);

  useEffect(() => {
    const fetchCryptoIcons = async () => {
      let cryptoIcons = {};

      const cachedData = localStorage.getItem("cryptoIcons");
      if (cachedData) {
        setCryptoIcons(JSON.parse(cachedData));
        return;
      }

      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/coins/markets",
          {
            params: {
              vs_currency: "usd",
              order: "market_cap_desc",
              per_page: 250,
              page: 1,
              sparkline: false,
              price_change_percentage: "",
              market_cap_change_percentage_24h: "",
              market_cap_change_24h: "",
              market_dominance_percentage: "",
              market_cap: "",
              volume: "",
              ids: recommendations.split(", ").join("%2C").toLowerCase(), // Make sure your recommendations are the IDs used by CoinGecko.
              locale: "en",
            },
          }
        );

        response.data.forEach((coin) => {
          cryptoIcons[coin.name] = coin.image;
        });

        localStorage.setItem("cryptoIcons", JSON.stringify(cryptoIcons));
        setCryptoIcons(cryptoIcons);
      } catch (error) {
        console.error("Error fetching crypto icons:", error);
      }
    };

    fetchCryptoIcons();
  }, [recommendations]);

  const displayCryptoDetails = (crypto) => {
    setSelectedCrypto(crypto);
    setShowPopup(true);
  };

  const nameToTickerMapping = {
    Bitcoin: "btc",
    Ethereum: "eth",
    Ripple: "xrp",
    Litecoin: "ltc",
    Cardano: "ada",
    Polkadot: "dot",
    "Bitcoin Cash": "bch",
    Chainlink: "link",
    TheSandbox: "sand",
    Stellar: "xlm",
    EOS: "eos",
    Tezos: "xtz",
    Tron: "trx",
    Uniswap: "uni",
    Monero: "xmr",
    Dash: "dash",
    Neo: "neo",
    CurveDAO: "crv",
    Decentraland: "mana",
    Synthetix: "snx",
    Cosmos: "atom",
    VeChain: "vet",
    Avalanche: "avax",
    Zcash: "zec",
    Solana: "sol",
    Filecoin: "fil",
    "Binance Coin": "bnb",
    Tether: "usdt",
    "USD Coin": "usdc", // Corrected here
    DAI: "dai",
    "PAX Gold": "paxg",
    Polygon: "matic",
    Chiliz: "chz",
  };


  const getCryptoLogoUrl = (cryptoName) => {
    const cryptoNameWithHyphen = cryptoName.replace(/ /g, "-"); // Remplace les espaces par des tirets
    const ticker = nameToTickerMapping[cryptoName];
    if (!ticker) {
      console.warn(`Ticker not found for ${cryptoName}`);
      return ""; // URL par défaut ou une image d'erreur
    }

    // Si la crypto-monnaie est Ripple, retournez une URL différente
    if (cryptoName.toLowerCase() === "ripple") {
      return `https://cryptologos.cc/logos/${ticker.toLowerCase()}-${ticker.toLowerCase()}-logo.png`;
    }
    //console.log(cryptoName.toLowerCase());
    if (cryptoName.toLowerCase() === "usd coin") {
      return `https://cryptologos.cc/logos/usd-coin-usdc-logo.png`;
    }
    if (cryptoName.toLowerCase() === "mana") {
      return `https://cryptologos.cc/logos/decentraland-mana-logo.png?v=029`;
    }
    if (cryptoName.toLowerCase() === "thesandbox") {
      return `https://cryptologos.cc/logos/the-sandbox-sand-logo.png?v=029`;
    }
    if (cryptoName.toLowerCase() === "curvedao") {
      return `https://cryptologos.cc/logos/curve-dao-token-crv-logo.png?v=029`;
    }


    return `https://cryptologos.cc/logos/${cryptoNameWithHyphen.toLowerCase()}-${ticker.toLowerCase()}-logo.png`;
  };


  const retryQuiz = () => {
    setCurrentQuestion(0);
    setShowResults(false);
    window.location.reload(); // Rafraîchir la page
  };


  const fetchCryptoReviews = async () => {
    try {
      const response = await axios.get(
        "https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/cryptocurrency"
      );
      const crypto_data = response.data.map((cryptocurrency) => {
        // Vérifie si crypto.review est une chaîne de caractères
        if (typeof cryptocurrency.review === "string") {
          return {
            ...cryptocurrency,
            reviewPoints: cryptocurrency.review,
          };
        } else {
          // Si ce n'est pas une chaîne de caractères, retourne le crypto sans la propriété reviewPoints
          return cryptocurrency;
        }
      });
      //console.log(crypto_data)
      setCryptoReviews(crypto_data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des critiques de cryptomonnaies:",
        error
      );
    }
  };

  function showEmail() {
    setShowEmailPopup(true);
  }

  const EmailPopup = ({ onClose }) => {
    return (
      <Translator
        cacheProvider={cacheProvider}
        to={to}
        from="en"
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <div className="popup-overlay">
          <div className="popup-content">
            <h3><Translate>Contact Information</Translate></h3>
            <p><Translate>Please contact: earvin@3decrypt.com</Translate></p>
            <button onClick={onClose}><Translate>Close</Translate></button>
          </div>
        </div>
      </Translator>
    );
  };

  const getDefaultLanguage = () => {
    // navigator.languages[0] est le premier choix de langue de l'utilisateur.
    // Utilisez navigator.language comme repli si navigator.languages n'est pas disponible.
    const userLanguage = (navigator.language || navigator.userLanguage).split('-')[0];
    return userLanguage; // 'en' est un repli général si aucune langue n'est détectée.
  };

  const [to, setTo] = useState(getDefaultLanguage());

  useEffect(() => {
    setTo(getDefaultLanguage()); // Met à jour la langue si nécessaire lors du chargement initial
    //console.log(getDefaultLanguage())
  }, []);

  return (
    <div className="app-container">
      <WarningPopup selectedLanguage={to} />
      <MenuBar getCryptoLogoUrl={getCryptoLogoUrl} displayCryptoDetails={displayCryptoDetails} />
      <Translator
        cacheProvider={cacheProvider}
        to={to}
        from="en"
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <NavBar />
        <select
          className="language-select"
          value={to}
          onChange={({ target: { value } }) => setTo(value)}
        >
          <option value="en">🇺🇸 English</option>
          <option value="es">🇪🇸 Spanish</option>
          <option value="fr">🇫🇷 French</option>
        </select>
        {attemptsExhausted ? (
          <div className="referral-container">
            <h2 style={{ textAlign: "center", marginTop: "20px" }}>
              <Translate>Attempts Depleted</Translate>
            </h2>
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              <Translate>You have used all your attempts.</Translate>
            </p>
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              <Translate>Invite friends to earn more attempts!</Translate>
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <button className="referral-button" onClick={showEmail}>
                <Translate>Invite Friends</Translate>
              </button>
              {showEmailPopup && (
                <EmailPopup onClose={() => setShowEmailPopup(false)} />
              )}
            </div>
          </div>

        ) : (
          <>
            {showPopup && selectedCrypto && (
              <CryptoPopup
                crypto={selectedCrypto}
                onClose={() => setShowPopup(false)}
                getCryptoLogoUrl={getCryptoLogoUrl}
                cryptoReviews={cryptoReviews}
                selectedLanguage={to}
              />
            )}
            {showResults ? (
              <ResultsShow
                recommendations={recommendations}
                attempts={attempts}
                isLoading={isLoading}
                displayCryptoDetails={displayCryptoDetails}
                getCryptoLogoUrl={getCryptoLogoUrl}
                retryQuiz={retryQuiz}
              />
            ) : (
              <>

                {username == null ? (
                  <UserForm />
                ) : (
                  <QuestionsForm onQuizComplete={() => setShowResults(true)} />
                )}
              </>
            )}
          </>
        )}
      </Translator>
    </div>

  );

};

export default App;
