import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Carousel from "../Carrousel";
import MultiLevelGauge from "../MultiLevelGauge";
import { Translate, Translator } from "react-auto-translate";

const Index = ({
  crypto,
  onClose,
  getCryptoLogoUrl,
  cryptoReviews,
  cacheProvider,
  selectedLanguage,
}) => {
  const [activeTab, setActiveTab] = useState("review");
  const [flameCount, setFlameCount] = useState(0);
  const [coldCount, setColdCount] = useState(0);
  const [hasVotedFlame, setHasVotedFlame] = useState(false);
  const [hasVotedCold, setHasVotedCold] = useState(false);

  useEffect(() => {
    const flameCountStorage = localStorage.getItem("flameCount");
    const coldCountStorage = localStorage.getItem("coldCount");
    const hasVotedFlameStorage = localStorage.getItem("hasVotedFlame");
    const hasVotedColdStorage = localStorage.getItem("hasVotedCold");

    if (flameCountStorage) {
      setFlameCount(parseInt(flameCountStorage));
    }

    if (coldCountStorage) {
      setColdCount(parseInt(coldCountStorage));
    }

    if (hasVotedFlameStorage) {
      setHasVotedFlame(true);
    }

    if (hasVotedColdStorage) {
      setHasVotedCold(true);
    }
  }, []);

  const toggleFlameVote = () => {
    if (!hasVotedFlame) {
      setFlameCount(flameCount + 1);
      localStorage.setItem("flameCount", flameCount + 1);
      localStorage.setItem("hasVotedFlame", true);
      setHasVotedCold(false);
      setColdCount(0);
      localStorage.removeItem("coldCount");
      localStorage.removeItem("hasVotedCold");
    } else {
      setFlameCount(flameCount - 1);
      localStorage.setItem("flameCount", flameCount - 1);
      localStorage.removeItem("hasVotedFlame");
    }
    setHasVotedFlame(!hasVotedFlame);
  };

  const toggleColdVote = () => {
    if (!hasVotedCold) {
      setColdCount(coldCount + 1);
      localStorage.setItem("coldCount", coldCount + 1);
      localStorage.setItem("hasVotedCold", true);
      setHasVotedFlame(false);
      setFlameCount(0);
      localStorage.removeItem("flameCount");
      localStorage.removeItem("hasVotedFlame");
    } else {
      setColdCount(coldCount - 1);
      localStorage.setItem("coldCount", coldCount - 1);
      localStorage.removeItem("hasVotedCold");
    }
    setHasVotedCold(!hasVotedCold);
  };

  // Récupérer la revue et les audits pour la crypto sélectionnée
  const selectedReview = cryptoReviews.find((item) => item.name === crypto);
  //const selectedAudit = cryptoAudits.find(item => item.name === crypto); // Ajustez selon la structure de vos données

  // Définir le contenu à afficher en fonction de l'onglet actif
  let content;
  if (activeTab === "review") {
    content = selectedReview
      ? selectedReview.review
      : "No review available for this crypto.";
  } else if (activeTab === "audit") {
    //console.log(selectedReview)
    content = selectedReview
      ? selectedReview.audit
      : "No audit available for this crypto.";
  }

  let contentToDisplay;

  if (selectedReview.community_trust > 0) {
    contentToDisplay = (
      <Carousel>
        <div className="card">
          <b>Community Trust</b>
          <br></br>
          <MultiLevelGauge score={selectedReview.community_trust} />
        </div>
        <div className="card">
          <b>Market Stability</b>
          <br></br>
          <MultiLevelGauge score={selectedReview.market_stability} />
        </div>
        <div className="card">
          <b>Governance</b>
          <br></br>
          <MultiLevelGauge score={selectedReview.governance} />
        </div>
        <div className="card">
          <b>Network Security</b>
          <br></br>
          <MultiLevelGauge score={selectedReview.network_security} />
        </div>
      </Carousel>
    );
  } else {
    contentToDisplay = <div>No data available for this cryptocurrency</div>;
  }

  // Styles des onglets
  const tabStyle = {
    cursor: "pointer",
    padding: "10px 20px",
    borderRadius: "20px",
    marginRight: "10px",
  };

  const reviewTabStyle = {
    ...tabStyle,
    backgroundColor: activeTab === "review" ? "purple" : "#e0e0df",
    color: activeTab === "review" ? "white" : "black",
  };

  const auditTabStyle = {
    ...tabStyle,
    backgroundColor: activeTab === "audit" ? "purple" : "#e0e0df",
    color: activeTab === "audit" ? "white" : "black",
  };

  return (
    <div className={`popup-container ${crypto ? "show" : ""}`}>
      <Translator
        cacheProvider={cacheProvider}
        to={selectedLanguage}
        from="en"
        googleApiKey="AIzaSyDdBSx0ogSpfG_7UDLS72WZTpkJmAyAXEw"
      >
        <div className="popup-content">
          {/* Logo et Titre */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <img
              src={getCryptoLogoUrl(crypto)}
              alt={crypto}
              style={{
                marginRight: "10px",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
              }}
            />
            <h2 style={{ margin: 0 }}>
              {crypto} ({selectedReview ? selectedReview.symbol : ""})
            </h2>
          </div>

          {/* Onglets */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <div onClick={() => setActiveTab("review")} style={reviewTabStyle}>
              <Translate>Review</Translate>
            </div>
            <div onClick={() => setActiveTab("audit")} style={auditTabStyle}>
              <Translate>Audit</Translate>
            </div>
          </div>

          {/* Contenu */}
          <div
            style={{
              padding: "10px",
              backgroundColor: "#f7f7f7",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {activeTab === "review" &&
              content.split(/\n\n/).map((paragraph, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: "justify",
                    marginBottom: "20px",
                    fontWeight: "bold",
                  }}
                >
                  <Translate>{paragraph}</Translate>
                </div>
              ))}

            {activeTab === "audit" && (
              <div style={{ margin: "20px 0" }}>{contentToDisplay}</div>
            )}
          </div>

          {/* Bouton de fermeture */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "15px" }}>
  <button onClick={onClose}>
    <Translate>Close</Translate>
  </button>
  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
    <button onClick={toggleFlameVote}>🔥</button>
    {flameCount}
    <button onClick={toggleColdVote}>❄️</button>
    {coldCount}
  </div>
</div>

        </div>
      </Translator>
    </div>
  );
};

export default Index;
