import React from "react";
import { Translate } from "react-auto-translate";
import styles from "./style.module.scss";
import Loader from "../Loader";

const ResultsShow = ({
  recommendations,
  attempts,
  isLoading,
  displayCryptoDetails,
  getCryptoLogoUrl,
  retryQuiz,
}) => {
  const score = (rec) => {
    let scoreValue;
    let linkcta;
    switch (rec) {
      case "Bitcoin":
        scoreValue = 8.1;
        linkcta = "https://2ly.link/1ycF6";
        break;
      case "Ethereum":
        scoreValue = 8.1;
        linkcta = "https://2ly.link/1ycF8";
        break;
      case "Tether":
        scoreValue = 7.3;
        linkcta = "https://2ly.link/1ycF9";
        break;
      case "Polygon":
        scoreValue = 7.3;
        linkcta = "https://2ly.link/1ycFA";
        break;
      case "DAI":
        scoreValue = 7.4;
        linkcta = "https://2ly.link/1ycFC";
        break;
      case "Cosmos":
        scoreValue = 7.2;
        linkcta = "https://2ly.link/1ycFF";
        break;
      case "Synthetix":
        scoreValue = 6.8;
        linkcta = "https://2ly.link/1ycFG";
        break;
      case "TheSandbox":
        scoreValue = 6.7;
        linkcta = "https://2ly.link/1ycFH";
        break;
      case "Decentraland":
        scoreValue = 6.7;
        linkcta = "https://2ly.link/1ycFK";
        break;
      case "CurveDAO":
        scoreValue = 6.5;
        linkcta = "https://2ly.link/1ycFM";
        break;
      default:
        scoreValue = 0; // or any default value
    }
    
    let color;
    if (scoreValue >= 0 && scoreValue < 3) {
      color = 'FF6666';
    } else if (scoreValue >= 3 && scoreValue < 5) {
      color = '#FFBA66';
    } else if (scoreValue >= 5 && scoreValue < 7) {
      color = '#EBFF66';
    } else if (scoreValue >= 7 && scoreValue <= 10) {
      color = '#87ff66';
    }

    return { scoreValue, color, linkcta };
  };

  const displayRecommendations = () => {
    if (recommendations) {
      const recArray = recommendations
        .split(/,\s|\n/)
        .map((item) => item.trim());
      localStorage.setItem("recommendations", JSON.stringify(recArray));
      return (
        <div className={styles.pagecards}>
          <ul
            style={{ listStyleType: "none", padding: 0 }}
            className={styles.card_container}
          >
            {recArray.map((rec, index) => {
              const { scoreValue, color, linkcta } = score(rec);
              return (
                <li
                  key={index}
                  className={styles.card}
                  onClick={() => displayCryptoDetails(rec)}
                  onMouseEnter={(e) => {
                    e.target.style.transform = "scale(1.05)"; // Échelle agrandie au survol
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.transform = "scale(1)"; // Retour à l'échelle normale
                  }}
                >
                  <div
                    className={styles.circle}
                    style={{ backgroundColor: color }}
                  >
                    <span className={styles.rating}>{scoreValue}</span>
                  </div>
                  <img
                    src={getCryptoLogoUrl(rec)}
                    alt={rec}
                    className={styles.img}
                  />
                  <span className={styles.span}>{rec}</span>
                  <div className={styles.buttonContainer}>
                  <button className={styles.cardButton} onClick={() => handleButtonClick(linkcta)}>
                    BUY
                  </button>
                </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  };

  const handleButtonClick = (linkcta) => {
    window.open(linkcta, "_blank");
    // Ajoutez ici le comportement souhaité lors du clic du bouton
  };
  return (
    <div className={styles.results}>
      <h2 style={{ textAlign: "center", marginTop: "20px", marginBottom: "50px" }}>
        <Translate>Your recommended cryptocurrencies are:</Translate>
      </h2>
      {isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        displayRecommendations()
      )}
      {attempts >= 3 ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Translate>You have exhausted your attempts.</Translate>
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button className="answer-button" onClick={retryQuiz}>
            <Translate>Retry</Translate>
          </button>
        </div>
      )}
    </div>
  );
};

export default ResultsShow;
