import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import { Translate } from "react-auto-translate";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import WarningPopup from "../WarningPopup";

const Index = () => {
  const username = localStorage.getItem("userRegistered");
  const [firstQuestionAnswer, setFirstQuestionAnswer] = useState("");
  const [secondQuestionAnswer, setSecondQuestionAnswer] = useState("");
  const [country, setCountry] = useState("");
  const [platform, setPlatform] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [attempts, setAttempts] = useState(null);
  const [attemptsExhausted, setAttemptsExhausted] = useState(false);
  const [allAttempts, setAllAttempts] = useState(false);
  const [lastAttempt, setLastAttempt] = useState(false);

  const handleFirstQuestionSubmit = async () => {
    if (!firstQuestionAnswer.trim() || !secondQuestionAnswer.trim()) {
      toast.error("Please provide both your first name and email.");
      return;
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(secondQuestionAnswer)) {
      toast.error("Please provide a valid email address.");
      return;
    }

    const userData = {
      username: String(firstQuestionAnswer),
      email: String(secondQuestionAnswer),
      risk_appetite: 0, // valeur par défaut, à ajuster selon vos besoins
      knowledge_level: 0, // valeur par défaut, à ajuster selon vos besoins
      register_date: new Date().toISOString(), // Ajoutez la date d'inscription
      country: country, // Ajoutez le pays, déterminé à partir du sous-domaine
      platform: platform, // Ajoutez la plateforme, extraite du nom de domaine
    };

    try {
      if (!localStorage.getItem("userRegistered")) {
        const response = await axios.post(
          "https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/user",
          [userData]
        );
        localStorage.setItem("userRegistered", secondQuestionAnswer);
        window.location.reload();
      }

      const res = await axios.get(
        `https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/user_infos/${userData.email}`
      );

      const userInfo = res.data[0];

      setAttempts(userInfo.quizattempts);

      if (userInfo.quizattempts === 0) {
        setAllAttempts(true);
      }

      if (userInfo.quizattempts >= 3) {
        setAttemptsExhausted(true);
      }

      if (userInfo.quizattempts === 2) {
        setLastAttempt(true);
      }

      localStorage.setItem("userRegistered", secondQuestionAnswer);
      setCurrentQuestion(0);
      setShowResults(false);
      window.location.reload();
    } catch (error) {
      console.error("Error during user verification or creation:", error);
    }
  };

  useEffect(() => {
    if (username) {
      const checkAndCreateUser = async () => {
        try {
          const res = await axios.get(
            `https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/user_infos/${username}`
          );

          if (!res.data || res.data.length === 0) {
            const newUser = {
              username: username,
              email: secondQuestionAnswer,
              risk_appetite: 0,
              knowledge_level: 0,
              register_date: new Date().toISOString(),
              country: country,
              platform: platform,
            };

            await axios.post(
              "https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/user",
              [newUser]
            );

            window.location.href = `${
              window.location.href.split("?")[0]
            }?t=${new Date().getTime()}`;
          }
        } catch (error) {
          console.error("Error during user verification or creation:", error);
        }
      };

      checkAndCreateUser();
    }
  }, [username]);

  return (
    <div>
      <div className={styles.form_container}>
        <form>
          <div className={styles.form_group}>
            <label htmlFor="firstQuestionAnswer">
              <Translate>Firstname:</Translate>
            </label>
            <input
              type="text"
              id="firstQuestionAnswer"
              className={styles.form_input}
              value={firstQuestionAnswer}
              onChange={(e) => setFirstQuestionAnswer(e.target.value)}
              required
            />
          </div>
          <br></br>
          <div className={styles.form_group}>
            <label htmlFor="secondQuestionAnswer">Email:</label>
            <input
              type="text"
              id="secondQuestionAnswer"
              className={styles.form_input}
              value={secondQuestionAnswer}
              onChange={(e) => setSecondQuestionAnswer(e.target.value)}
              required
            />
          </div>
          <br></br>
          <button
            type="button"
            onClick={handleFirstQuestionSubmit}
            className={styles.answer_button}
          >
            <Translate>Start</Translate>
          </button>
        </form>

        <br></br>
        <ToastContainer
          position="bottom-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};

export default Index;
