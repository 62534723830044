import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Translate } from "react-auto-translate";
import axios from "axios";

const Index = ({ onQuizComplete }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [progress, setProgress] = useState(0);
  const [attempts, setAttempts] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const totalQuestions = questions.length;
  const progressPercentage = (progress / totalQuestions) * 100;
  const [userInfo, setUserInfo] = useState(null);
  const username = localStorage.getItem("userRegistered");
  const [attemptsExhausted, setAttemptsExhausted] = useState(false);

  const [allAttempts, setallAttempts] = useState(false);

  const [lastAttempt, setLastAttempt] = useState(false);

  useEffect(() => {
    axios
      .get(
        "https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/questions"
      )
      .then((res) => setQuestions(res.data))
      .catch((err) => console.log(err));
  }, []);

  // Fetch user info from API
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (localStorage.getItem("userRegistered") !== null) {
        const res = await axios.get(
          `https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/user_infos/${localStorage.getItem(
            "userRegistered"
          )}`
        );

        setUserInfo(res.data[0]);
        setAttempts(res.data[0].quizattempts);

        if (res.data[0].quizattempts === 0) {
          setallAttempts(true);
        }

        if (res.data[0].quizattempts >= 3) {
          setAttemptsExhausted(true);
        }
        if (res.data[0].quizattempts === 2) {
          setLastAttempt(true);
        }
      }
    };
    fetchUserInfo();
  }, []);

  const handleAnswerClick = (answer) => {
    setProgress((prevProgress) => prevProgress + 1);
    let parsedAnswer;
    try {
      parsedAnswer = JSON.parse(answer);
    } catch (e) {
      console.error("Error parsing JSON:", e);
      return;
    }

    const userAnswer = {
      user_id: userInfo.id,
      question_id: questions[currentQuestion].question_id,
      response_id: parsedAnswer.Response_id,
    };

    axios
      .post(
        `https://3xqrwyn316.execute-api.eu-west-1.amazonaws.com/Prod/user_answers/${username}`,
        [userAnswer]
      )
      .then((res) => {
        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < totalQuestions) {
          setCurrentQuestion(nextQuestion);
        } else {
          onQuizComplete(); // Call the callback function here
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <div className="app-question">
        <Translate>{questions[currentQuestion]?.question}</Translate>
      </div>
      <div className="answer-container">
        {questions[currentQuestion]?.responses?.stringValues?.map(
          (answer, i) => (
            <button
              key={i}
              className="answer-button"
              onClick={() => handleAnswerClick(answer)}
            >
              <Translate>{JSON.parse(answer).Response}</Translate>
            </button>
          )
        )}
        <div
          style={{
            height: "10px",
            width: "200px",
            backgroundColor: "#e0e0df",
            borderRadius: "5px",
            margin: "30px auto -60px auto",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `${progressPercentage}%`,
              backgroundColor: "#404e7c",
              borderRadius: "5px",
            }}
          ></div>
        </div>
      </div>
      <div className="attempts-container">
        <Translate>
          You have {attempts === 2 ? "one" : `${3 - attempts}`} attempt
          {attempts === 2 ? "" : "s"}
          {attempts === 2 ? " left" : ""}
        </Translate>
      </div>
    </div>
  );
};

export default Index;
